// Dependencies
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import styled, { keyframes } from 'styled-components'

export const keyframeScaleDownEntering = keyframes`
  0% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

const AnimationContent = styled.div`
  opacity: 0;
  transform: scale(1.5);
  animation-duration: 1s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-timing-function: 'ease-in-out';
  animation-name: ${props => props.animated && keyframeScaleDownEntering};
`

const ScaleDown = props => {
  const [ref, inView, entry] = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  const [animated, setAnimated] = useState(false)

  useEffect(() => {
    if (inView) setAnimated(true)
  }, [inView])

  return (
    <div ref={ref}>
      <AnimationContent animated={animated}>{props.children}</AnimationContent>
    </div>
  )
}

// Components PropTypes
ScaleDown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
}

export default ScaleDown
