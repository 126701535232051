// Dependencies
import React from 'react'
import 'intersection-observer'
import objectFitImages from 'object-fit-images'
objectFitImages();

// Styles
import './base.css'

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return <>{children}</>
  }
}

export default Template
