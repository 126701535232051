// Dependencies
import styled from 'styled-components'

// Constants
import { sizesMin, sizesMax } from '../../constants/breakpoints'
import colors from '../../constants/colors'
import { sourceCodePro } from '../../constants/fonts'

export const ProjectHeader = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: block;
  position: relative;

  .react-parallax {
    width: 100%;
    height: 100%;

    .react-parallax-background-children {
      width: 100%;

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;

        img {
          font-family: 'object-fit: contain; object-position: center center;';
        }
      }
    }
  }
`

export const ProjectHeaderContent = styled.div`
  max-height: 576px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0% 10%;
  background-color: ${props => props.color};

  height: 40vw;
  @media screen and (max-width: ${sizesMax.tablet}) {
    height: 60vw;
  }
`

export const ProjectsGrid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin: 20px 0;

  a {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;

    :after {
      content: '';
      position: absolute;
      height: 7px;
      bottom: 0;
      left: 0;
      width: 100%;
      background: ${colors.violet};
      transition: all 0.2s ease-in;
      opacity: 0;
      z-index: 100;
    }

    :hover {
      :after {
        opacity: 1;
      }
    }
  }

  li {
    background: ${colors.black};
    @media screen and (min-width: ${sizesMin.laptop}) {
      width: calc((100% / 3) - (20px / 3));
      margin-right: calc(20px / 2);
      margin-bottom: calc(30px / 3);

      &:nth-child(3n),
      &:last-child {
        margin-right: 0;
      }
    }

    @media screen and (min-width: ${sizesMin.tablet}) and (max-width: ${sizesMax.laptop}) {
      width: calc((100% / 2) - (20px / 4));
      margin-right: calc(20px / 2);
      margin-bottom: calc(30px / 4);

      &:nth-child(2n),
      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: ${sizesMax.tablet}) {
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
    max-width: 100%;
    height: auto;
    
    img {
      font-family: 'object-fit: contain; object-position: center center;';
    }
  }

  @media screen and (min-width: ${sizesMin.laptop}) {
    .gatsby-image-wrapper,
    li {
      height: 300px;
      min-height: 300px;
    }
  }

  @media screen and (min-width: ${sizesMin.tablet}) and (max-width: ${sizesMax.laptop}) {
    .gatsby-image-wrapper,
    li {
      height: 250px;
      min-height: 250px;
    }
  }

  @media screen and (min-width: ${sizesMin.mobileL}) and (max-width: ${sizesMax.tablet}) {
    .gatsby-image-wrapper,
    li {
      height: 200px;
      min-height: 200px;
    }
  }

  @media screen and (max-width: ${sizesMax.mobileL}) {
    .gatsby-image-wrapper,
    li {
      height: 150px;
      min-height: 150px;
    }
  }
`

export const ProjectContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 5;
  position: relative;

  @media screen and (max-width: ${sizesMax.tablet}) {
    flex-direction: column;
  }
`

export const ProjectItem = styled.div`
  background-color: ${props => props.color};
  padding: 0% 25%;
  height: auto;
  width: 100%;
  position: relative;
`

export const Description = styled.div`
  width: 45%;
  overflow: hidden;
  position: relative;
  z-index: 10;

  @media screen and (max-width: ${sizesMax.tablet}) {
    width: 100%;
  }
`
export const Involvement = styled.div`
  width: 45%;
  overflow: hidden;
  position: relative;
  z-index: 10;

  @media screen and (max-width: ${sizesMax.tablet}) {
    width: 100%;
    margin-top: 30px;
  }

  h4 {
    font-style: italic;
    color: ${colors.lighterViolet};
    ${sourceCodePro};

    font-size: 18px;
    text-decoration: underline;

    @media screen and (max-width: ${sizesMax.laptop}) {
      font-size: 16px;
    }

    @media screen and (max-width: ${sizesMax.mobileL}) {
      font-size: 14px;
    }
  }

  ul {
    position: relative;
    z-index: 6;
    display: flex;
    flex-wrap: wrap;

    li {
      span {
        ${sourceCodePro};
        padding: 20px;

        @media (max-width: ${sizesMax.tablet}) {
          padding: 15px;
        }

        @media (max-width: ${sizesMax.mobileL}) {
          padding: 10px;
        }

        font-weight: 700;
        margin: 0 10px 10px 0;
        color: ${colors.white};
        z-index: 2;
        position: relative;
        display: block;
        z-index: 2;

        font-size: 16px;
        @media screen and (max-width: ${sizesMax.laptop}) {
          font-size: 14px;
        }

        @media screen and (max-width: ${sizesMax.mobileL}) {
          font-size: 14px;
        }
      }

      span:after {
        content: '';
        background: ${colors.shadow};
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: -1;
      }
    }
  }
`

export const Screens = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      font-family: 'object-fit: contain; object-position: center center;';
    }
  }

  div.screens {
    display: block;
    clear: both;
    width: 100% !important;
    margin: 0 !important;
    padding: 0;
    background-color: ${colors.lighterGrey} !important;
    background-position: center center !important;
    background-size: 40px auto !important;

    img {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
`

export const ScreenContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  max-width: ${props => (props.mobile ? `800px` : '100%')};
  margin: 0 auto;
`
const framesStyle = `
  width: 100%;
  vertical-align: top;

  display: inline-block;

  @media screen and (min-width: ${sizesMin.laptop}) {
    margin: 0 0 60px;
  }

  @media screen and (min-width: ${sizesMin.tablet}) and (max-width: ${sizesMax.laptop}) {
    margin: 0 0 40px;
  }

  @media screen and (min-width: 0px) and (max-width: ${sizesMax.tablet}) {
    margin: 0 0 20px;
  }

`
export const BrowserFrame = styled.div`
  ${framesStyle};

  header {
    width: 100%;
    background: ${colors.white};
    border-bottom: solid 1px ${colors.lighterGrey};
    padding: 10px;
    height: 34px;
    display: block;
    position: relative;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: solid 1px ${colors.lighterGrey};
  }

  > div {
    background: ${colors.white};
    border: solid 1px ${colors.lighterGrey};
    border-top: none;
    border-bottom: none;
  }

  footer {
    width: 100;
    background: ${colors.white};
    border-top: solid 1px ${colors.lighterGrey};
    height: 12px;
    display: block;
    position: relative;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: solid 1px ${colors.lighterGrey};
  }

  svg {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    display: inline-block;

    circle {
      cx: 6;
      cy: 6;
      r: 6;
      fill: ${colors.lighterGrey};
    }
  }

  @media screen and (min-width: 0px) and (max-width: ${sizesMax.laptop}) {
    header {
      height: 17px;
      padding: 0 5px;
    }

    footer {
      height: 6px;
      padding: 0;
    }
    svg {
      width: 6px;
      height: 6px;
      margin-right: 3px;
      display: inline-block;

      circle {
        cx: 3;
        cy: 3;
        r: 3;
      }
    }
  }
`

export const MobileFrame = styled.div`
  ${framesStyle};

  max-width: 300px;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;

  header {
    width: 100%;
    background: ${colors.white};
    padding: 10px;
    height: 50px;
    display: block;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: solid 1px ${colors.lighterGrey};
    border-bottom: none;
  }

  > div {
    padding: 0 2%;
    background: ${colors.white};
    .gatsby-image-wrapper {
      border: solid 3px ${colors.lighterGrey};
    }
    border: solid 1px ${colors.lighterGrey};
    border-top: none;
    border-bottom: none;
  }

  footer {
    width: 100;
    background: ${colors.white};
    padding: 10px;
    height: 70px;
    display: block;
    position: relative;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: solid 1px ${colors.lighterGrey};
    border-top: none;
  }

  header svg {
    width: 18px;
    height: 18px;
    margin: 9px auto 0;
    display: block;

    circle {
      fill: ${colors.lightGrey};
      stroke: ${colors.lighterGrey};
      stroke-width: 3;
      cx: 9;
      cy: 9;
      r: 7;
    }
  }

  footer svg {
    width: 50px;
    height: 50px;
    margin: 0px auto 0;
    display: block;

    circle {
      fill: ${colors.white};
      stroke: ${colors.lighterGrey};
      stroke-width: 2;
      cx: 25;
      cy: 25;
      r: 23;
    }
  }

  @media screen and (min-width: 0px) and (max-width: ${sizesMax.mobileL}) {
    header,
    footer {
      height: 40px;
    }

    header svg {
      width: 10px;
      height: 10px;
      margin: 8px auto 0;
      display: block;

      circle {
        fill: ${colors.lightGrey};
        stroke: ${colors.lighterGrey};
        stroke-width: 2;
        cx: 5;
        cy: 5;
        r: 3;
      }
    }

    footer svg {
      width: 25px;
      height: 25px;
      margin: -5px auto 0;

      circle {
        fill: ${colors.white};
        stroke: ${colors.lighterGrey};
        stroke-width: 1;
        cx: 12.5;
        cy: 12.5;
        r: 12.3;
      }
    }
  }
`

export const Column = styled.div`
  width: calc(50% - 20px);
  @media screen and (max-width: ${sizesMax.tablet}) {
    display: none;
  }
  @media screen and (min-width: ${sizesMin.tablet}) {
    display: block;
  }

  &:nth-child(2n) {
    padding-top: 50px;
  }
`

export const Row = styled.div`
  width: 100%;
  @media screen and (max-width: ${sizesMax.tablet}) {
    display: block;
  }
  @media screen and (min-width: ${sizesMin.tablet}) {
    display: none;
  }
`
