// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// Styles
import { ProjectItem } from './Work.styled'

const ProjectCard = ({ color, slug, logo, title }) => {
  return (
    <Link
      to={`/work/${slug}`}
      href
      onClick={() => {
        trackCustomEvent({
          category: 'Project',
          action: 'Click',
          label: title,
        })
      }}
    >
      <ProjectItem color={color}>
        <Img
          fluid={logo.fluid}
          imgStyle={{
            objectFit: 'contain',
          }}
        />
      </ProjectItem>
    </Link>
  )
}

// Components PropTypes
ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcWebp: PropTypes.string,
      srcSetWebp: PropTypes.string,
      sizes: PropTypes.string,
      aspectRatio: PropTypes.number,
      base64: PropTypes.string,
    }),
  }).isRequired,
}

export default ProjectCard
