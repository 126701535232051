// Dependencies
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import styled, { keyframes } from 'styled-components'

// Constants
import colors from '../../constants/colors'

export const keyframeRevealingEntering = keyframes`
  0% {
    transform: translate(-100%, 0px);
  }
  100% {
    transform: translate(0, 0px);
  }
`
export const keyframeRevealingExiting = keyframes`
  0% {
    transform: translate(0, 0px);
  }
  100% {
    transform: translate(-100%, 0px);
  }
`

const AnimationWrapper = styled.div`
  position: relative;
  display: ${props => (props.fluid ? 'block' : 'table')};
`

const AnimationContent = styled.div`
  opacity: ${props => (props.animate && props.animated ? '1' : '0')};
`
const AnimationBg = styled.div`
  position: relative;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;

  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;

    background: ${colors.lighterViolet};
    background: linear-gradient(90deg, ${colors.violet}, ${colors.lightViolet});
    transform: translate(-100%, 0px);
    animation-duration: 0.3s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-timing-function: 'ease-in-out';

    animation-name: ${props =>
      props.animate && !props.animated && keyframeRevealingEntering};
    animation-name: ${props =>
      props.animate && props.animated && keyframeRevealingExiting};
  }
`

const Reveal = ({ children, fluid }) => {
  const [ref, inView, entry] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const [animate, setAnimate] = useState(false)
  const [animated, setAnimated] = useState(false)

  useEffect(() => {
    if (inView) setTimeout(() => setAnimate(true), 0)
  }, [inView])

  useEffect(() => {
    const randomInterval = Math.floor(Math.random() * (600 - 300 + 1) + 300)
    if (animate) setTimeout(() => setAnimated(true), randomInterval)
  }, [animate])

  return (
    <AnimationWrapper ref={ref} fluid={fluid}>
      <AnimationBg animate={animate} animated={animated} />
      <AnimationContent animate={animate} animated={animated}>
        {children}
      </AnimationContent>
    </AnimationWrapper>
  )
}

// Components PropTypes
Reveal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

export default Reveal
