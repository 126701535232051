// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const getMeta = ({
  websiteTitle,
  websiteDescription,
  keywords,
  twitterHandler,
  shareImage,
  websiteUrl,
}) => [
  { content: websiteTitle, name: 'author' },
  { content: websiteDescription, name: 'description' },
  {
    name: 'keywords',
    content: keywords,
  },

  { content: 'summary_large_image', name: 'twitter:card' },
  { content: twitterHandler, name: 'twitter:site' },
  { content: websiteTitle, name: 'twitter:title' },
  { content: websiteDescription, name: 'twitter:description' },
  { content: twitterHandler, name: 'twitter:creator' },
  { content: shareImage.file.url, name: 'twitter:image:src' },
  { content: websiteUrl, name: 'twitter:domain' },

  { content: websiteUrl, property: 'og:url' },
  { content: 'website', property: 'og:type' },
  { content: websiteTitle, property: 'og:title' },
  { content: shareImage.file.url, property: 'og:image' },
  { content: websiteDescription, property: 'og:description' },
  { content: websiteTitle, property: 'og:site_name' },
  { content: 'en_GB', property: 'og:locale' },

  { content: websiteTitle, itemprop: 'name' },
  { content: websiteDescription, itemprop: 'description' },
  { content: shareImage.file.url, itemprop: 'image' },
]

const getLink = ({ websiteUrl, favicon16x16, favicon32x32 }) => [
  { rel: 'canonical', href: websiteUrl },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: `${favicon16x16.file.url}`,
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: `${favicon32x32.file.url}`,
  },
  { rel: 'shortcut icon', type: 'image/png', href: `${favicon32x32.file.url}` },
]

export const HelmetEl = ({
  websiteTitle,
  description,
  keywords,
  twitterHandler,
  shareImage,
  websiteUrl,
  favicon16x16,
  favicon32x32,
}) => (
  <Helmet
    title={websiteTitle}
    meta={getMeta({
      description,
      keywords,
      twitterHandler,
      shareImage,
      websiteUrl,
    })}
    link={getLink({
      websiteUrl,
      favicon16x16,
      favicon32x32,
    })}
  />
)

// Components PropTypes
HelmetEl.propTypes = {
  websiteTitle: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  twitterHandler: PropTypes.string,
  shareImage: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  websiteUrl: PropTypes.string,
  favicon16x16: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  favicon32x32: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
}
HelmetEl.defaultProps = {
  websiteTitle: '',
  description: '',
  keywords: '',
  twitterHandler: '',
  shareImage: { file: { url: '' } },
  websiteUrl: '',
  favicon16x16: { file: { url: '' } },
  favicon32x32: { file: { url: '' } },
}

export default HelmetEl
