// Dependencies
import styled from 'styled-components'
import { Link } from 'gatsby'

// Constants
import colors from '../../constants/colors'
import { sourceCodePro } from '../../constants/fonts'
import { maxWidth } from '../../constants/general'
import { breakpoints, sizesMin, sizesMax } from '../../constants/breakpoints'

const sectionStyle = `
  ${maxWidth};

  font-size: 16px;
  @media screen and (max-width: ${sizesMax.laptop}) {
    font-size: 14px;
  }
  @media screen and (max-width: ${sizesMax.mobileL}) {
    font-size: 14px;
  }

  h3 {
    font-style: italic;
    position: relative;
    z-index: 6;
    display: block;
    color: ${colors.lighterViolet};
    ${sourceCodePro};
    font-size: 24px;
    font-weight: 700;
    text-decoration: underline;
    font-style: italic;
    @media screen and (max-width: ${sizesMax.laptop}) {
      font-size: 22px;
    }
    @media screen and (max-width: ${sizesMax.mobileL}) {
      font-size: 18px;
    }
  }

  h4 {
    position: relative;
    z-index: 6;
    display: block;
    color: ${colors.lighterViolet};
    ${sourceCodePro};
    font-weight: 700;
    font-size: 18px;
    @media screen and (max-width: ${sizesMax.laptop}) {
      font-size: 16px;
    }
    @media screen and (max-width: ${sizesMax.mobileL}) {
      font-size: 14px;
    }
  }

  p {
    line-height: 1.4;
    z-index: 6;
    margin-bottom: 10px;
    color: ${colors.white};
    ${sourceCodePro};
    font-size: 16px;
    @media screen and (max-width: ${sizesMax.laptop}) {
      font-size: 14px;
    }
    @media screen and (max-width: ${sizesMax.mobileL}) {
      font-size: 14px;
    }
  }
`

export const Section = styled.div`
  ${sectionStyle}

  padding: 50px 30px;

  @media (max-width: ${sizesMax.tablet}) {
    padding: 50px 30px;
  }

  @media (max-width: ${sizesMax.mobileL}) {
    padding: 50px 5vw;
  }
`

export const HomeSection = styled.div`
  ${sectionStyle}

  padding: 50px 30px;

  @media (max-width: ${sizesMax.tablet}) {
    padding: 30px 20px;
  }

  @media (max-width: ${sizesMax.mobileL}) {
    padding: 20px 5vw;
  }
`

const titleStyle = `
    width: 100%;
    color: ${colors.lightViolet};
    opacity: .2;
    ${sourceCodePro};
    font-weight: 300;
    font-style: italic;
    z-index: 0;
    font-size: 300px;
    position: relative;
    display: block;
    text-align: right;

    font-size: 80px;
    letter-spacing: -.7rem;

    @media ${breakpoints.mobileM} {
        font-size: 80px;
        letter-spacing: -.7rem;
    }
    @media ${breakpoints.mobileL} {
        font-size: 100px;
        letter-spacing: -.9rem;
    }
    @media ${breakpoints.tablet} {
        font-size: 220px;
        letter-spacing: -2rem;
    }
    @media ${breakpoints.laptop} {
        font-size: 300px;
        letter-spacing: -1rem;
    }

`

export const HomeTitle = styled.div`
  ${titleStyle}
  margin-bottom: -50px;
  @media screen and (max-width: ${sizesMax.tablet}) {
    margin-bottom: -20px;
  }
`

export const SectionTitle = styled.div`
  ${titleStyle}
  margin-bottom: -115px;
  @media screen and (max-width: ${sizesMax.tablet}) {
    margin-bottom: -70px;
  }
`
const ctaStyle = `
  border: none;
  background: ${colors.lighterViolet};
  color: ${colors.black};
  ${sourceCodePro};
  transition: all .2s ease-in;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  z-index: 10;
  position: relative;
  padding: 20px 20px;
  width: 100%;
  display: block;
  margin-top: 30px;
  
  font-size: 16px;
  @media screen and (max-width: ${sizesMax.laptop}) {
    font-size: 14px;
  }
  @media screen and (max-width: ${sizesMax.mobileL}) {
    font-size: 14px;
  }

  &:focus,
  &:hover {
    background: ${colors.violet};
    color: ${colors.white};
  }
  @media screen and (max-width: ${sizesMax.mobileL}) {
    display: block;
    width: 100%;
  }
`
export const CtaLink = styled(Link)`
  ${ctaStyle}
`

export const Cta = styled.button`
  ${ctaStyle}
`
