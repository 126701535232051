/* Project specific Variables */
export const black = '#000000'
export const darkGrey = '#111111'
export const grey = '#666666'
export const lightGrey = '#cccccc'
export const lighterGrey = '#f0f0f0'
export const lightBlack = '#151515'
export const bg = '#0d0d0c'
export const violet = '#6067f1'
export const white = '#ffffff'
export const blue = '#040729'
export const lighterBlue = '#072a58'
export const lightViolet = '#8f94f5'
export const lighterViolet = '#bec1f9'
export const shadow = '#080B29'

export default {
  black,
  darkGrey,
  grey,
  lightGrey,
  lighterGrey,
  lightBlack,
  bg,
  violet,
  white,
  blue,
  lighterBlue,
  lightViolet,
  lighterViolet,
  shadow,
}
