// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// Components
import Reveal from '../Animation/Reveal'
import ProjectCard from './ProjectCard'

// Styles
import { Cta } from '../common/Section.styled'
import { ProjectsGrid } from './Work.styled'
import { useState } from 'react'
import FadeIn from '../Animation/FadeIn'

const ProjectsList = ({
  projects,
  title,
  shouldAllowExtra,
  excludeSlugs = [],
  compact,
  random,
}) => {
  const INCREMENT = 6
  const [limit, setLimit] = useState(INCREMENT)

  let projectsFiltered = projects.filter(
    project => excludeSlugs.indexOf(project.slug) === -1
  )

  projectsFiltered = random
    ? projectsFiltered.sort(() => Math.random() - 0.5)
    : projectsFiltered.sort((a, b) => new Date(b.date) - new Date(a.date))

  const total = projectsFiltered.length
  const showLoadMore = shouldAllowExtra && total > limit

  const projectsToShow = showLoadMore
    ? projectsFiltered.slice(0, limit).map((item, index) => {
        const i = index
        return <ProjectCard key={i} {...item} />
      })
    : projectsFiltered.map((item, index) => {
        const i = index
        return <ProjectCard key={i} {...item} />
      })

  const loadMore = () => {
    trackCustomEvent({
      category: 'Projects List',
      action: 'Click',
      label: 'Load More',
    })
    setLimit(limit + INCREMENT)
  }

  /* Other Projects / All Projects */
  const otherProjects = (
    <>
      {title && (
        <div>
          <Reveal>
            <h3>{title}</h3>
          </Reveal>
        </div>
      )}

      {projectsToShow && (
        <ProjectsGrid>
          {(compact ? projectsToShow.slice(0, 3) : projectsToShow).map(
            (project, i) => {
              return (
                <li key={'project_grid' + i}>
                  <FadeIn>{project}</FadeIn>
                </li>
              )
            }
          )}
        </ProjectsGrid>
      )}

      {showLoadMore && (
        <Reveal fluid>
          <Cta onClick={loadMore}>Show more</Cta>
        </Reveal>
      )}
    </>
  )

  return otherProjects
}

// Components PropTypes
ProjectsList.propTypes = {
  title: PropTypes.string,
  shouldAllowExtra: PropTypes.bool,
  excludeSlugs: PropTypes.arrayOf(PropTypes.string),
  compact: PropTypes.bool,
  random: PropTypes.bool,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      logo: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string,
          srcSet: PropTypes.string,
          srcWebp: PropTypes.string,
          srcSetWebp: PropTypes.string,
          sizes: PropTypes.string,
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
        }),
      }),
    })
  ),
}
ProjectsList.defaultProps = {
  title: '',
  shouldAllowExtra: false,
  excludeSlugs: [],
  compact: false,
  random: false,
  projects: [],
}

export default ProjectsList
